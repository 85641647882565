import React, { useState, useEffect } from "react";
import "./vocabcard.css";
import words from "./vocab.json";

function VocabCard({ word, definition }) {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="vocab-card" onClick={handleFlip}>
      <div className={`vocab-card-inner ${isFlipped ? "flipped" : ""}`}>
        <div className="vocab-card-front">
          <p className="vocab-word">{word}</p>
        </div>
        <div className="vocab-card-back">
          <p>{definition}</p>
        </div>
      </div>
    </div>
  );
}

function Vocabcard() {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  const getRandomWord = () => {
    const randomIndex = Math.floor(Math.random() * words.length);
    setCurrentWordIndex(randomIndex);
  };

  return (
    <div className="App">
      <h1>Vocab Card</h1>
      <VocabCard
        word={words[currentWordIndex].word}
        definition={words[currentWordIndex].definition}
      />
      <button onClick={getRandomWord} className="vocab-button">
        Next Word
      </button>
    </div>
  );
}

export default Vocabcard;
