import React, { useState, useEffect } from "react";
import "./workingpapers.scss";
import papers from "./workingpaper.json";

function WorkingPapers() {
  // Now you can safely access allWorkingPapers
  return (
    <div>
      <h1>Working Papers I Recommend</h1>
      <ul className="tile-list">
        {papers.map((item, index) => (
          <li key={index}>
            {console.log(item.authors)}
            <a
              href={item.paper_url}
              className="tile-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h2>{item.title}</h2>
              <p id="Authors">Authors: {item.authors.join(", ")}</p>
              <p id="Abstract-title">Abstract</p>
              <div className="abstract-wrapper">
                <p id="Abstract">{item.abstract}</p>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default WorkingPapers;
