import React, { useState } from 'react';
import './contac.scss';
import { useForm, ValidationError } from '@formspree/react';

const ContactPage = () => {
    const [state, handleSubmit, reset] = useForm("xzbnklnl");

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* Calendly Scheduling Section */}
            <div className="Scheduling">
                <h2>Schedule a Time with Me</h2>
                <iframe
                    title="Calendly Scheduling Page"
                    src="https://calendly.com/sam-jakshtis/30min"
                    style={{ width: '100%', height: '800px', border: 'none' }}
                ></iframe>
            </div>
            {/* Contact Me Section */}
            <div className='Contact'>
                <h2>Contact Me</h2>
                {state.succeeded ? (
                    <p>Thanks for contacting me!</p>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="email">
                            Email Address
                        </label>
                        <input
                            id="email"
                            type="email"
                            name="email"
                        />
                        <ValidationError
                            prefix="Email"
                            field="email"
                            errors={state.errors}
                        />
                        <label htmlFor="email">
                            Message
                        </label>
                        <textarea
                            id="message"
                            name="message"
                        />
                        <ValidationError
                            prefix="Message"
                            field="message"
                            errors={state.errors}
                        />
                        <button type="submit" disabled={state.submitting}>
                            Submit
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default ContactPage;

