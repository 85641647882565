import React, { useState, useEffect } from "react";
import "./movies.scss";
import movies from "./movies.json";

function Movies() {
  const sortedMovies = movies.sort((a, b) => b.rating - a.rating);

  return (
    <div>
      <h1>Movies I Reccomend</h1>
      <ul className="tiles">
        {sortedMovies.map((item, index) => (
          <li key={index}>
            <a
              href={item.movie_url.replace(/"/g, "")}
              target="_blank"
              className="movie_url"
            >
              <h2>{item.title}</h2>
              <p id="Movie-rating">Rating: {item.rating}/10</p>
              <div className="movie-wrapper">
                <br />
              </div>
            </a>
            <img src={item.poster_url} alt="Movie Poster" className="Pics" />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Movies;
