import React, { useState } from "react";
import Modal from "react-modal";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import WorkIcon from "@mui/icons-material/Work";
import SchoolIcon from "@mui/icons-material/School";
import StarIcon from "@mui/icons-material/Star";
import "./Home.scss";
import CloseIcon from "@mui/icons-material/Close";
import Typewriter from "typewriter-effect";
// import profileImage from "../../photos/SamPic.jpg";
import ImageSlideshow from "./imageslideshow";
import resume from "../../photos/2024 Sam Jakshtis Resume (1).pdf";
import Footer from "../Footer/footer";

function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  // Function to handle icon hover
  const handleIconHover = (item) => {
    console.log("hey");
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  // Function to close modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // const pdfUrl = 'frontend/public/Sam Jakshtis Resume (3).pdf';

  function handleClick() {
    window.open(resume, "_blank");
  }

  const timelineData = [
    {
      title: "Financial Data Engineer",
      company: "Darwin Homes",
      location: "Remote",
      role: "Drove the design and execution of efficient ETL processes for internal and external data warehouses, utilizing SQL and Python extensively for data gathering, manipulation, and analysis, while also leading multiple financial-focused projects from inception to implementation",
      Description_1:
        "Develop and implement efficient ETL processes surrounding internal and external warehouses",
      Description_2:
        "Daily use of SQl and Python for the gathering, manipulation and analyzation of financial data",
      Description_3:
        "Lead multiple financial focused projects for actionable development and implementation",
      date: "2023-Present",
    },
    {
      title: "Project Manager",
      company: "LandVest",
      location: "Boston, MA",
      role: "Contributed to revenue growth and operational efficiency by managing centralized databases, developing pricing models for high-value real estate deals, and leading data research projects to support strategic decision-making",
      Description_1:
        "Assisted in a 28% YOY increase in revenue for consulting department by maintaining an optimized central comparable sales and lease database for specific high-value comparable properties and markets",
      Description_2:
        "Developed multi-tiered pricing model for $+1B of real estate deals (development, acquisitions, and refinances for clients) using a combination of mySQL, VBA, and Python code",
      Description_3:
        "Led real estate data research projects for the Managing Director for exclusive presentation materials through collection, customization, and visualization of data sets for internal and external audiences",
      date: "2021 - 2023",
    },
    {
      title: "Comparative Unit Analyst",
      company: "Doorkee",
      location: "New York City, NY",
      role: "Played a pivotal role in leveraging data analytics to enhance decision-making processes, optimize pricing strategies, and streamline workflows, ultimately driving efficiency and maximizing revenue for property management firms.",
      Description_1:
        "Empirically analyzed price and movement trends for NYC apartments utilizing various ML practices",
      Description_2:
        "Reported optimized pricing models of apartment units to maximize rental income and minimize vacancy for +1,000 unit property management firms",
      Description_3:
        "Worked with firms and team members to update workflows to optimize efficiency and accessibility for the company through data analytics",
      date: "2019-2021",
    },
    {
      title: "Real Estate Sales Person",
      company: "Coldwell Banker",
      location: "Minnesota and Massachusetts",
      role: "Sold Houses",
      Description_1:
        "Created and implemented a centralized database system to optimize sales and customer engagement",
      Description_2:
        "Managed contracts, negotiations and all aspects of sales to finalize purchases and exceed customer expectations",
      Description_3:
        "Reviewed market research data and changed sales plans accordingly",
      date: "2017-2021",
    },
    {
      title: "Farm Manager",
      company: "Chestnut farms",
      location: "Hardwick, MA",
      role: "Helping on the family Livestock Farm",
      Description_1:
        "Established sustainable farming practices, including rotational grazing and organic feed sourcing, to promote environmental stewardship and animal welfare while maintaining cost-effectiveness",
      Description_2:
        "Implemented customer feedback mechanisms and market analysis to tailor product offerings, pricing strategies, and promotional activities, resulting in enhanced customer satisfaction and increased sales volumes",
      Description_3:
        "Evaluated equipment performance and conducted cost-benefit analyses to inform decisions on machinery upgrades or replacements, optimizing farm productivity and resource allocation.",
      date: "1998-Present",
    },
  ];

  return (
    <div>
      <div>
        <ImageSlideshow />
        <br />
        <div>
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .typeString("Hello World!")
                .callFunction(() => {
                  // console.log("String typed out!");
                })
                .pauseFor(2500)
                .start();
            }}
            options={{ wrapperClassName: "typewriter" }}
          />
          <div className="big-container">
            <div className="intro-container">
              <div className="first-intro intro-box">
                <h2 style={{ color: "black" }}>
                  {" "}
                  My name is Sam Jakshtis. I am a self-taught software
                  developer, a seasoned real estate professional with over a
                  decade of experience in all things finance and data analytics,
                  and a passionate part-time farmer.
                </h2>
              </div>
              <div className="second-intro intro-box">
                <h2 style={{ color: "black" }}>
                  {" "}
                  Feel free to explore this site to discover more about me or to
                  get in touch!
                </h2>
              </div>
              <div className="third-intro intro-box">
                <h2 style={{ color: "black" }}>
                  Quick Note: I use this site to practice and grow my coding
                  knowledge and skills. The site was last updated on June 5th,
                  2024.
                </h2>
              </div>
            </div>
          </div>
          {/* <Typewriter
            onInit={(typewriter) => {
              typewriter
                .pauseFor(2500) // Pause for 2500 milliseconds (2.5 seconds)
                .typeString(
                  "My name is Sam Jakshtis. I am a self-taught software developer, a seasoned real estate professional with over a decade of experience in all things finance and data analytics, and a passionate part-time farmer."
                )
                .callFunction(() => {
                  console.log("String typed out!");
                })
                .pauseFor(2500)
                .start();
            }}
            options={{ wrapperClassName: "typewriter" }}
          />
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .pauseFor(33500) // Pause for 2500 milliseconds (2.5 seconds)
                .typeString(
                  "Feel free to explore this site to discover more about me or to get in touch!"
                )
                .callFunction(() => {
                  console.log("String typed out!");
                })
                .pauseFor(2500)
                .start();
            }}
            options={{ wrapperClassName: "typewriter" }}
          /> */}
        </div>
      </div>
      <h1 className="Title">My Timeline</h1>
      <button className="resume-button" onClick={handleClick}>
        Resumé
      </button>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          contentStyle={{
            background: "linear-gradient(to right, #e66465, #9198e5)",
            color: "#fff",
            fontWeight: "bold",
            left: "calc(55% - 7px)",
          }}
          contentArrowStyle={{ borderRight: "7px solid " }}
          iconStyle={{
            background: "linear-gradient(to right, #e66465, #9198e5)",
            color: "#fff",
          }}
          icon={<SchoolIcon />}
        >
          <div className="info">
            <h2 className="vertical-timeline-element-title">
              <b>
                <u>Masters of Science</u>
              </b>
            </h2>
            <h3>
              <i>School:</i> MIT
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Location: Boston, Massachusetts
            </h4>
            <br />
            <p onClick={handleIconHover}>MSRED Program</p>
            <p onClick={handleIconHover}></p>
            <p>
              <i>2024-2025</i>
            </p>
          </div>
        </VerticalTimelineElement>
        <VerticalTimeline>
          {timelineData.map((item, index) => (
            <VerticalTimelineElement
              key={index}
              className="vertical-timeline-element--work"
              contentStyle={{
                background: "linear-gradient(45deg, #f3ec78, #af4261)",
                color: "#fff",
                fontWeight: "bold",
              }}
              contentArrowStyle={{ borderRight: "7px solid " }}
              iconStyle={{
                background: "linear-gradient(to right, #f3ec78, #af4261)",
                color: "#fff",
              }}
              icon={<WorkIcon />}
            >
              <div className="info">
                <h2
                  className="vertical-timeline-element-title"
                  onClick={() => handleIconHover(item)}
                >
                  <b>
                    <u>{item.title}</u>
                  </b>
                </h2>
                <h3 onClick={() => handleIconHover(item)}>
                  <i>Company:</i> {item.company}
                </h3>
                <h4
                  className="vertical-timeline-element-subtitle"
                  onClick={() => handleIconHover(item)}
                >
                  Location: {item.location}
                </h4>
                <br />
                <p onClick={() => handleIconHover(item)}>{item.description}</p>
                <p onClick={() => handleIconHover(item)}>
                  {" "}
                  <i>{item.role}</i>
                </p>
                <p onClick={() => handleIconHover(item)}>
                  {" "}
                  <i>{item.date}</i>
                </p>
              </div>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          contentStyle={{
            background: "linear-gradient(to right, #e66465, #9198e5)",
            color: "#fff",
            fontWeight: "bold",
            left: "calc(55% - 7px)",
          }}
          contentArrowStyle={{ borderRight: "7px solid " }}
          iconStyle={{
            background: "linear-gradient(to right, #e66465, #9198e5)",
            color: "#fff",
          }}
          icon={<SchoolIcon />}
        >
          <div className="info">
            <h2
              className="vertical-timeline-element-title"
              onClick={handleIconHover}
            >
              <b>
                <u>Bachelors of Arts</u>
              </b>
            </h2>
            <h3>
              <i>School:</i> Macalester College
            </h3>
            <h4
              className="vertical-timeline-element-subtitle"
              onClick={handleIconHover}
            >
              Location: St. Paul, Minnesaota
            </h4>
            <br />
            <p onClick={handleIconHover}>
              Graduated with Degrees in Economics & Data Science
            </p>
            <p onClick={handleIconHover}>
              Captain of Football team in 2020-2021
            </p>
            <p>
              <i>2017-2021</i>
            </p>
          </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          contentStyle={{
            background: "linear-gradient(to right, #e66465, #9198e5)",
            color: "#fff",
            fontWeight: "bold",
            right: "calc(55% + 7px)",
          }}
          contentArrowStyle={{ borderRight: "7px solid " }}
          iconStyle={{
            background: "linear-gradient(to right, #e66465, #9198e5)",
            color: "#fff",
          }}
          icon={<SchoolIcon />}
        >
          <div className="info">
            <h2
              className="vertical-timeline-element-title"
              onClick={handleIconHover}
            >
              <b>
                <u>High School</u>
              </b>
            </h2>
            <h3>
              <i>School:</i> Worcester Academy
            </h3>
            <h4
              className="vertical-timeline-element-subtitle"
              onClick={handleIconHover}
            >
              Location: Worcester, Massachusetts
            </h4>
            <br />
            <p onClick={handleIconHover}>
              Graudated cum laude with a distinguishment in Economics
            </p>
            <p onClick={handleIconHover}>
              Captain of Football team in 2016-2017
            </p>
            <p>
              <i>2014-2017</i>
            </p>
          </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          iconStyle={{
            background:
              "linear-gradient(to bottom right, #ffff66 0%, #ff5050 100%)",
            color: "#fff",
          }}
          icon={<StarIcon />}
        />
      </VerticalTimeline>
      <Modal
        isOpen={isModalOpen && selectedItem}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="Modal"
      >
        {selectedItem && (
          <div className="modal-content">
            <button className="close-button" onClick={closeModal}>
              <CloseIcon />
            </button>
            <h2 className="modal-title">{selectedItem.title}</h2>
            <p className="modal-company">
              <i>{selectedItem.company}</i>
            </p>
            <p className="modal-date">{selectedItem.date}</p>
            <p className="modal-location">{selectedItem.location}</p>
            <ul className="bullet-list">
              <li className="modal-description">
                {selectedItem.Description_1}
              </li>
              <li className="modal-description">
                {selectedItem.Description_2}
              </li>
              <li className="modal-description">
                {selectedItem.Description_3}
              </li>
            </ul>
          </div>
        )}
      </Modal>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
}

export default Home;
