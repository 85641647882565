import React, { useState, useEffect } from "react";
import profileImage from "../../photos/SamPic.jpg";
import selfpic from "../../photos/profile.jpg";
import farming from "../../photos/farming.jpg";
import skiing from "../../photos/skiing.jpg";

function ImageSlideshow() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const images = [selfpic, profileImage, farming, skiing];

  useEffect(() => {
    const interval = setInterval(() => {
      setOpacity(0); // Start fading out
      setTimeout(() => {
        setCurrentImageIndex((index) => (index + 1) % images.length);
        setOpacity(1); // Fade in new image
      }, 1000); // Match this duration with the transition duration
    }, 4000); // Change image every 4 seconds (1 second fade out, 3 seconds display)

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <img
        src={images[currentImageIndex]}
        alt="Slideshow"
        style={{
          width: "20%",
          height: "350px",
          display: "inline",
          borderRadius: "20%",
          marginBottom: "25px",
          transition: "opacity 1s ease-in-out",
          opacity: opacity,
        }}
      />
      <br />
    </div>
  );
}

export default ImageSlideshow;
