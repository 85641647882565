import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  function getYear() {
    return new Date().getFullYear();
  }

  return (
    <footer className="sticky-footer">
      <div className="container">
        <div className="footer-row">
          <a href="https://wasp-lang.dev/">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Made with Wasp
              <img
                src="https://wasp-lang.dev/img/wasp-logo-eqpar-circle.png"
                alt="Wasp"
                style={{
                  maxWidth: "10%" /* or any other value you prefer */,
                  height: "auto",
                  marginLeft: "2%",
                  alignContent: "center",
                }}
              ></img>
            </div>
          </a>
        </div>
        <div className="footer-row">Made by Me</div>
        <div className="footer-row">
          <Link to="/contact">Contact Me</Link>
        </div>
      </div>
      <div className="footer-text">&copy; {getYear()} All rights reserved.</div>
    </footer>
  );
};

export default Footer;
