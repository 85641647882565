import { Link, useNavigate, useLocation } from "react-router-dom";
import "./Header.scss";
import React, { useState, useEffect, useRef } from "react";

function Dropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(false); // Close the dropdown on location change
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <button onClick={toggleDropdown} className="dropdown-button">
        More About Myself
      </button>
      <div
        id="dropdown"
        className={`dropdown-menu ${isOpen ? "show" : ""}`}
        onClick={(event) => event.stopPropagation()}
      >
        <Link to="/workingpaper" className="dropdown-item">
          Working Paper
        </Link>
        <Link to="/movies" className="dropdown-item">
          Movies
        </Link>
        <Link to="/podcasts" className="dropdown-item">
          Podcasts
        </Link>
      </div>
    </div>
  );
}

function Header() {
  return (
    <header className="App-header">
      <div className="header-left">
        <Link to="/" className="header-name">
          Sam Jakshtis
        </Link>
      </div>
      <div className="header-right">
        <nav>
          <Link to="/">Home</Link>
          <Dropdown />
          <Link to="/languages">Tech Stack</Link>
          <Link to="/contact">Contact</Link>
        </nav>
      </div>
    </header>
  );
}

export default Header;
