import React, { useState, useEffect } from "react";
import "./podcasts.scss";
import podcasts from "./podcasts.json";

function Podcasts() {
  const sortedpods = podcasts.sort((a, b) => a.id - b.id);

  return (
    <div>
      <h1>Podcasts I Reccommend</h1>
      <ul className="ptiles">
        {sortedpods.map((item, index) => (
          <a href={item.podcast_url} target="_blank" className="podcast_url">
            <li key={index} className="podcast-item">
              <div className="podcast-content">
                <h2 style={{ marginTop: "50px" }}>{item.title}</h2>
                <p>{item.Description}</p>
              </div>
            </li>
          </a>
        ))}
      </ul>
    </div>
  );
}

export default Podcasts;
