import "./bubble.scss";
import Marquee from "react-fast-marquee";
import "./languages.css";
import { useState, useEffect } from "react";
import dataJson from "./data.json";
import { BrowserRouter as Router, Link } from "react-router-dom"; // Import Link directly
import vocabcard from "../Vocab/vocabcard";

export default function Languages(props) {
  return (
    <>
      <div className="Bubs">
        <h1> Languages and Technologies </h1>
        <Marquee pauseOnHover={true} speed={150} className="marquee">
          {(Object.entries(dataJson) || []).map(([key, value], index) => (
            <div key={index} className="tile">
              <img src={value.src} alt={value.name} className="image" />
              <p className="text">{value.name}</p>
            </div>
          ))}
        </Marquee>
        <h1> Notable Projects </h1>
        <h3> Please reach out for source code/data </h3>
        <div className="elements">
          <div className="container-projects">
            <Link to="/vocab-card" className="custom-link">
              <div className="row" style={{ color: "blue" }}>
                {console.log("hi")}
                Play: My Vocab Cards
              </div>
            </Link>
            <div className="row">
              The need for better causal inference in real estate <br />
              <span
                style={{ fontSize: "12px", color: "gray", marginTop: "4px" }}
              >
                <p>*Ongoing*</p>
              </span>
            </div>
            <div className="row">
              Theoretical: Blockchain based real estate title database
            </div>
            <div className="row">
              Hedonic regression of the Twin Cities single family homes
            </div>
            <div className="row">
              Live, feeder cattle future price corelations with various
              commodities
            </div>
            <div className="row">
              Autoregressive Analysis for prediting day 0 for Lake Mead
            </div>
            <div className="row">
              NFL Big Data Bowl 2024: Elements of Tackling
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
